"use client";

import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import ChamferedBox from "./ChamferedBox";
import CashBackPath from "./path/CashBackPath";
import BonusPath from "./path/BonusPath";
import CashPath from "./path/CashPath";
import { useTranslations } from "next-intl";
// import WalletSvg from "@/components/svg/wallet";
const InfoCard = ({
  type,
  wallets,
}: {
  type: Record<"zxc" | "asd" | "qwe", "Cash" | "Bonus" | "Cash Back">;
  wallets: Record<string, string | number>[];
}) => {
  const t = useTranslations();
  return (
    <svg width="100%" height="100%" viewBox="0 0 225 150">
      {/* <foreignObject x="0" y="0" width="100%" height="100%">
    <img src="/assets/img/svg/wallet.svg" alt="" />
  </foreignObject> */}
      <g id="zxc" transform="scale(2) translate(4 15)">
        {type.zxc === "Cash Back" && <CashBackPath fill="#E3C55C" />}
        {type.zxc === "Bonus" && <BonusPath fill="#E3C55C" />}
        {type.zxc === "Cash" && <CashPath fill="#E3C55C" />}
      </g>
      <text x="60" y="64" fill="#fff" fontSize={type.zxc === "Cash Back" ? 20 : 28}>
        {/* {type.zxc} Wallet */}
        {t("wallet_" + type.zxc.toLowerCase())}
      </text>
      <text x="5" y="110" fill="#fff" fontSize={32}>
        {/* {(123456).toLocaleString()} {currency} */}
        {wallets.find((wallet) => wallet.walletType === type.zxc)?.balance?.toLocaleString()}{" "}
        {wallets.find((wallet) => wallet.walletType === type.zxc)?.currency}
      </text>
    </svg>
  );
};

const WalletButton = ({
  id,
  wallet,
  onClick,
}: {
  id: "zxc" | "asd" | "qwe";
  wallet: Record<"zxc" | "asd" | "qwe", "Cash" | "Bonus" | "Cash Back">;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button
    id={id}
    className="size-12 rounded-full border bg-[#1F1F1F] p-2 transition-transform hover:scale-110 active:scale-95 [&>*]:pointer-events-none"
    onClick={onClick}
  >
    <svg height={"100%"} width={"100%"} viewBox="0 0 22 22">
      {wallet[id] === "Cash Back" && <CashBackPath fill="#E3C55C" />}
      {wallet[id] === "Bonus" && <BonusPath fill="#E3C55C" />}
      {wallet[id] === "Cash" && <CashPath fill="#E3C55C" />}
    </svg>
  </button>
);

export default function Wallet({ wallets }: { wallets: Record<string, string | number> }) {
  // const [type, setType] = useState<"Cash" | "Bonus" | "Cash Back">("Cash");
  const [type, setType] = useState<Record<"zxc" | "asd" | "qwe", "Cash" | "Bonus" | "Cash Back">>({
    zxc: "Cash",
    asd: "Bonus",
    qwe: "Cash Back",
  });

  const convertedWallet = useMemo(() => {
    return [
      {
        walletType: "Bonus",
        balance: wallets.bonusBalance,
        currency: wallets.currency,
      },
      {
        walletType: "Cash",
        balance: wallets.withdrawableBalance,
        currency: wallets.currency,
      },
      {
        walletType: "Cash Back",
        balance: wallets.withdrawRestrictionAmount,
        currency: wallets.currency,
      },
    ];
  }, [wallets]);

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) =>
    setType((prev) => ({
      ...prev,
      zxc: prev[(e.target as HTMLButtonElement).id as "asd" | "qwe"],
      [(e.target as HTMLButtonElement).id as "asd" | "qwe"]: prev.zxc,
    }));

  return (
    <ChamferedBox className="flex-row items-start gap-0 text-lg font-normal">
      <InfoCard type={type} wallets={convertedWallet} />
      {/* <div className="ml-4 flex h-full flex-col items-center justify-center space-y-2">
        <WalletButton id="qwe" wallet={type} onClick={onClick} />
        <WalletButton id="asd" wallet={type} onClick={onClick} />
      </div> */}
    </ChamferedBox>
  );
}
