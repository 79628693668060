"use client";

import { twJoin, twMerge } from "tailwind-merge";

function ChamferedBox(props: JSX.IntrinsicElements["div"]) {
  return (
    <div
      {...props}
      className={twMerge(
        "relative flex items-center justify-center gap-4 rounded-2xl border-2 border-yellow-200 bg-black bg-opacity-50 px-12 text-[1.75em] font-bold leading-none max-h-40",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

export default ChamferedBox;