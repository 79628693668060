"use client";

import { updatePassword } from "@/lib/actions";
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { useDisclosure, UseDisclosureReturn } from "@nextui-org/use-disclosure";
import { useTranslations } from "next-intl";
import React, { FocusEvent, useEffect, useRef, useState, useTransition } from "react";
import { PiEyeBold, PiEyeSlashBold, PiCheckCircleFill, PiXCircleFill } from "react-icons/pi";
import colors from "tailwindcss/colors";

export default function ChangePasswordModal({
  disclosure: { isOpen, onClose, onOpenChange },
}: {
  disclosure: UseDisclosureReturn;
}) {
  const FormRef = useRef<HTMLFormElement>(null);
  const t = useTranslations();

  const AlertDisclosure = useDisclosure();
  const [alertMessage, setAlertMessage] = useState<string>("Passwords do not match");
  const [inputTypes, setInputTypes] = useState({
    oldPassword: "password",
    newPassword: "password",
    confirmNewPassword: "password",
  });

  const [inputErrors, setInputErrors] = useState<
    Record<"oldPassword" | "newPassword" | "confirmNewPassword", string | undefined>
  >({
    oldPassword: undefined,
    newPassword: undefined,
    confirmNewPassword: undefined,
  });

  const OnRetypePasswordInputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newPassword = FormRef.current?.querySelector<HTMLInputElement>('input[name="newPassword"]')?.value;
    const isMatch = value === newPassword;
    setInputErrors((prev) => ({ ...prev, confirmNewPassword: isMatch ? undefined : "Passwords do not match" }));
  };

  const UpdatePasswordHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    // console.log("Form Data: ", formData);

    const oldPassword = formData.get("oldPassword") as string;
    const newPassword = formData.get("newPassword") as string;
    const confirmNewPassword = formData.get("confirmNewPassword") as string;


    formData.forEach((value, key) => {
      if (value.toString().length < 1) {
        setInputErrors((prev) => ({ ...prev, [key]: "This field is required" }));
      } else if (value.toString().length < 8) {
        setInputErrors((prev) => ({ ...prev, [key]: "Password must be at least 8 characters" }));
      } else {
        setInputErrors((prev) => ({ ...prev, [key]: undefined }));
      }
    });

    if (newPassword !== confirmNewPassword) {
      // console.log("Passwords do not match");
      setAlertMessage("Passwords do not match");
      AlertDisclosure.onOpen();
      return;
    }

    // Update password
    // console.log("Old Password: ", oldPassword);
    // console.log("New Password: ", newPassword);
    // console.log("Confirm New Password: ", confirmNewPassword);

    const res = await updatePassword(oldPassword, newPassword);
    // console.log("Response: ", res);

    if (res.status === "SUCCESS") {
      // console.log("Password updated successfully");
      // onClose();
      setAlertMessage("Password updated successfully");
      AlertDisclosure.onOpen();
    } else if (res.status === "CURRENT_PASSWORD_INCORRECT") {
      // console.log("Password update failed");
      setAlertMessage("The current password was incorrect");
      AlertDisclosure.onOpen();
    } else if (res.status === "INVALID_PASSWORD") {
      setAlertMessage(
        "The new password is invalid. This may occur when the new password selected is not valid according to the password rules. Ex: Too Short, Too Long, ..."
      );
      AlertDisclosure.onOpen();
    } else if (res.status === "VALIDATION_FAIL") {
      setAlertMessage("Validation error. Please check the fields and try again");
      AlertDisclosure.onOpen();
    }
  };

  // useEffect(() => {
  //   if (AlertDisclosure.isOpen && alertMessage === "Password updated successfully") {
  //     setAlertMessage("");
  //   }
  // }, [AlertDisclosure.isOpen, alertMessage]);

  return (
    <>
      <Modal isOpen={AlertDisclosure.isOpen} onOpenChange={AlertDisclosure.onOpenChange}>
        <ModalContent>
          {(onAlertClose) => (
            <>
              <ModalHeader>
                {alertMessage === "Password updated successfully" ? (
                  <PiCheckCircleFill size={86} color={colors.green[600]} className="mx-auto" />
                ) : (
                  <PiXCircleFill size={86} color={colors.red[600]} className="mx-auto" />
                )}
              </ModalHeader>
              <ModalBody>
                <span className="text-center text-black">{alertMessage}</span>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  className="font-semibold text-black"
                  onPress={
                    alertMessage === "Password updated successfully"
                      ? () => (onAlertClose(), onClose())
                      : () => onAlertClose()
                  }
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        // className="dark"
        scrollBehavior="inside"
        placement="center"
        isDismissable={false}
        size="sm"
      >
        <ModalContent>
          <ModalHeader>
            <span className="text-2xl font-bold text-black">{t('change_password')}</span>
          </ModalHeader>
          <ModalBody>
            <form
              ref={FormRef}
              onSubmit={UpdatePasswordHandler}
              className="grid grid-flow-row grid-cols-2 gap-2 gap-y-4"
              onFocus={(e) => {
                if (e.target instanceof HTMLInputElement) {
                  e.target.select();
                  setInputErrors((prev) => ({ ...prev, [e.target.name]: undefined }));
                }
              }}
            >
              <Input
                name="oldPassword"
                label={t('old_password')}
                labelPlacement="outside"
                type={inputTypes.oldPassword}
                placeholder={t('enter_old_password')}
                className="col-span-full"
                errorMessage={inputErrors.oldPassword}
                isInvalid={!!inputErrors.oldPassword}
                classNames={{
                  innerWrapper: "w-full flex flex-row",
                  input: "w-full p-0 text-white",
                }}
                endContent={
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setInputTypes((prev) => ({
                        ...prev,
                        oldPassword: prev.oldPassword === "password" ? "text" : "password",
                      }));
                    }}
                  >
                    {inputTypes.oldPassword === "password" ? (
                      <PiEyeBold size={16} color="#fff" />
                    ) : (
                      <PiEyeSlashBold size={16} color="#fff" />
                    )}
                  </span>
                }
              />
              <Input
                name="newPassword"
                label={t('new_password')}
                labelPlacement="outside"
                type={inputTypes.newPassword}
                placeholder={t('enter_new_password')}
                errorMessage={inputErrors.newPassword}
                isInvalid={!!inputErrors.newPassword}
                className="col-span-full"
                // onInput={AmountInputOnChangeHandler}
                classNames={{
                  innerWrapper: "w-full flex flex-row",
                  input: "w-full p-0 text-white",
                }}
                endContent={
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setInputTypes((prev) => ({
                        ...prev,
                        newPassword: prev.newPassword === "password" ? "text" : "password",
                      }));
                    }}
                  >
                    {inputTypes.newPassword === "password" ? (
                      <PiEyeBold size={16} color="#fff" />
                    ) : (
                      <PiEyeSlashBold size={16} color="#fff" />
                    )}
                  </span>
                }
              />
              <Input
                name="confirmNewPassword"
                label={t('confirm_password')}
                labelPlacement="outside"
                type={inputTypes.confirmNewPassword}
                placeholder={t('re_enter_new_password')}
                className="col-span-full"
                // onInput={AmountInputOnChangeHandler}
                classNames={{
                  innerWrapper: "w-full flex flex-row",
                  input: "w-full p-0 text-white",
                }}
                // onFocus={}
                errorMessage={inputErrors.confirmNewPassword}
                isInvalid={!!inputErrors.confirmNewPassword}
                onInput={OnRetypePasswordInputHandler}
                endContent={
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setInputTypes((prev) => ({
                        ...prev,
                        confirmNewPassword: prev.confirmNewPassword === "password" ? "text" : "password",
                      }));
                    }}
                  >
                    {inputTypes.confirmNewPassword === "password" ? (
                      <PiEyeBold size={16} color="#fff" />
                    ) : (
                      <PiEyeSlashBold size={16} color="#fff" />
                    )}
                  </span>
                }
              />
              <Button type="submit" className="col-span-full font-semibold text-black" radius="sm" color="primary">
                {t('change_password')}
              </Button>
            </form>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}
