"use client";

import { uploadUserImage } from "@/lib/actions";
import ChamferedBox from "./ChamferedBox";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ChangePasswordModal from "./ChangePasswordModal";
import { useTranslations } from "next-intl";

const thumbsContainer: Record<string, any> = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: Record<string, any> = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function Profile({
  playerInfo,
  userImages,
}: {
  playerInfo?: Record<string, any>;
  userImages?: Record<string, any>;
}) {
  // const [userImage, setUserImage] = useState<string>(
  //   (userImages as any[]).length > 0
  //     ? userImages[0].userImageFiles[0].url
  //     : "https://placehold.co/200x200?text=Profile\nPicture"
  // );

  // function blobToBase64(blob: Blob) {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.readAsDataURL(blob);
  //   });
  // }

  // const DropzoneDisclosure = useDisclosure();
  // const [files, setFiles] = useState<any[]>([]);
  // const { open } = useDropzone({
  //   accept: {
  //     "image/*": [],
  //   },

  //   onDrop: async (acceptedFiles) => {
  //     const image = acceptedFiles[0] as File;
  //     console.log("image", image);

  //     // const data = await blobToBase64(image)

  //     // console.log("rest", rest);
  //     var reader = new FileReader();
  //     reader.readAsDataURL(image);
  //     reader.onloadend = async function () {
  //       var base64data = reader.result;
  //       // console.log(base64data);
  //       const rest = await uploadUserImage(base64data as string);
  //     };

  //     // setFiles(
  //     //   acceptedFiles.map((file) =>
  //     //     Object.assign(file, {
  //     //       preview: URL.createObjectURL(file),
  //     //     })
  //     //   )
  //     // );
  //   },
  // });

  // const thumbs = files.map((file) => (
  //   <div style={thumb} key={file.name}>
  //     <div style={thumbInner}>
  //       <img
  //         src={file.preview}
  //         className="size-full aspect-square object-cover object-center"
  //         // Revoke data uri after image is loaded
  //         onLoad={() => {
  //           URL.revokeObjectURL(file.preview);
  //         }}
  //       />
  //     </div>
  //   </div>
  // ));

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  // }, []);

  // useEffect(() => {
  //   console.log(userImage);
  // }, [userImages]);

  // console.log("userImages", userImage);

  const changePasswordDisclosure = useDisclosure();
  const t = useTranslations();

  return (
    <>
      <ChangePasswordModal disclosure={changePasswordDisclosure} />
      <ChamferedBox>
        <svg width="100%" height="100%" viewBox="0 0 300 150" pointerEvents={"fill"}>
          <defs>
            <mask id="picture-mask">
              <image
                href="https://placehold.co/200x200?text=Profile\nPicture"
                x="80%"
                y="50%"
                transform="translate(-50 -50)"
                width="100"
                height="100"
              />
            </mask>
            <clipPath id="clip">
              <circle cx="80%" cy="50%" r="50" fill="#fff" />
            </clipPath>
          </defs>
          <text y="37.5%" fill="#fff" fontWeight={400} fontSize={16}>
            {t("hello")}
          </text>
          <text y="55%" fill="#fff" fontWeight={400} fontSize={28}>
            {playerInfo?.userId || "Unknown"}
          </text>
          <text y="72%" fill="#fff" fontWeight={300} fontSize={14}>
            {t("welcome_message")}
          </text>
          {/* <g clipPath="url(#clip)">
            
            <image
              href={
                files.length > 0
                  ? files[0].preview
                  : playerInfo?.extraInfo?.profilePicture || "https://placehold.co/200x200?text=Profile\nPicture"
              }
              x="80%"
              y="50%"
              transform="translate(-50 -50)"
              width="100"
              height="100"
            />
            <rect
              className="pointer-events-auto cursor-pointer opacity-0 hover:opacity-100"
              x="80%"
              y="50%"
              transform="translate(-50 20)"
              pointerEvents={"fill"}
              fill="#222"
              fillOpacity={0.25}
              width={100}
              height={50}
              onClick={() => {
                console.log("open");
                open();
                // DropzoneDisclosure.onOpen();
              }}
            />
          </g> */}
          {/* <rect
            className="pointer-events-auto cursor-pointer opacity-0 hover:opacity-100"
            x="80%"
            y="50%"
            transform="translate(-50 20)"
            pointerEvents={"fill"}
            fill="#222"
            fillOpacity={0.25}
            width={100}
            height={50}
          ></rect> */}
        </svg>
        <button onClick={changePasswordDisclosure.onOpen} className="absolute right-0 top-2 w-12 transition-transform hover:scale-125">
          <SettingsIcon />
        </button>
      </ChamferedBox>
    </>
  );
}
