"use client";
import clsx from "clsx";
import { usePathname, useRouter, Link } from "@/i18n/routing";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { ProfileLinks } from "@/lib/constant";
import ListSharpIcon from "@mui/icons-material/ListSharp";
import { useTranslations } from "next-intl";

export default function ProfileNavigator() {
  const pathname = usePathname();
  const router = useRouter();

  const HeaderList = _.chain(_.cloneDeep(ProfileLinks))
    .toPairs()
    .map(([text, href]) => ({ href, text }))
    .value();

  const [selectedKeys, setSelectedKeys] = useState<string>(Object.keys(ProfileLinks)[0]);

  // useEffect(() => {
  // 	redirect(LinkList[selectedKeys]);
  // }, [selectedKeys]);

  function HandleSelectionChange(key: string) {
    // console.log(key);

    setSelectedKeys(key);
    router.push(key);
  }

  const t = useTranslations();

  return (
    <>
      <div className="hidden size-full min-h-12 grid-cols-6 items-center justify-center gap-2 rounded-lg bg-yellow-100 px-2 text-[0.8em] text-black sm:flex">
        {Object.entries(ProfileLinks).map(([text, href]) => (
          <Link
            key={href}
            className={clsx(
              "flex size-full items-center justify-center rounded-md bg-transparent py-2 font-bold transition-all hover:bg-yellow-500",
              pathname === href && "bg-yellow-300"
            )}
            href={href}
          >
            {t(text)}
          </Link>
        ))}
      </div>
      <Dropdown>
        <DropdownTrigger>
          <Button
            size="lg"
            radius="sm"
            className="relative w-full bg-yellow-100 font-bold capitalize text-black sm:hidden"
          >
            {t(HeaderList.find((item) => item.href === pathname)?.text)}
            <ListSharpIcon className="absolute right-0 top-1/2 mr-4 -translate-y-1/2 fill-black" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          classNames={{ base: "w-full" }}
          aria-label="Single selection example"
          variant="flat"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selectedKeys}
          onSelectionChange={(cur) => HandleSelectionChange(cur.currentKey as string)}
        >
          {Object.entries(ProfileLinks).map(([text, href]) => (
            <DropdownItem key={href}>{t(text)}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
